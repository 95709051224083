<template>
  <div class="account-edit-page container" v-if="currentAccount && editableAccount">
    <h1>Edit account</h1>
    <hr />

    <form @submit.prevent="">
      <div class="mb-3">
        <label for="login" class="form-label">Login</label>
        <input type="text" class="form-control" id="login" autocomplete="username" disabled :value="editableAccount.login" />
      </div>
      <div class="mb-3" v-if="currentAccount.hasPermission('accounts.create')">
        <label for="password" class="form-label">Password</label>
        <div class="control col-md-12 d-flex">
          <div class="password">
            <input type="text" class="form-control" id="password" v-model="password" autocomplete="new-password" />
            <i class="fa fa-dice" @click="generatePassword"></i>
          </div>
          <button class="btn btn-primary" @click="changePassword">Change</button>
        </div>
      </div>
      <div class="mb-3">
        <label for="company" class="form-label">Company</label>
        <select
          class="form-select"
          id="company"
          :disabled="!currentAccount.hasPermission('admin.accounts.edit')"
          v-model="companyId"
          @change="changeCompany"
        >
          <option value="default" disabled>Select company</option>
          <option v-for="company in companyList" :value="company.id">{{ company.name }}</option>
        </select>
      </div>
      <div class="mb-3">
        <label class="form-label">Permissions</label>
        <div v-if="editableAccountPermissions.length > 0">
          <span class="permission badge bg-primary" v-for="permission in editableAccountPermissions"
            >{{ permission }} <i class="fa fa-times" @click="revokePermission(permission)"></i
          ></span>
        </div>
        <div v-else class="text-muted">None</div>
      </div>

      <div class="control add-permission mb-3">
        <label for="add-permission" class="form-label">Add permission</label>
        <SelectWithSearch
          inputId="add-permission"
          :inputValue="selectedPermission"
          :items="availablePermissions"
          @search="onPermissionSearch"
        />
        <button class="btn btn-primary col" type="button" @click="grantPermission">Add</button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getCompanyList, getPermissionList, findAccount } from '@/api';
import { accountService } from '@/modules/account/account.service';
import { randomString, RandomStringFlags } from 'fifty-seven-llc-common/dist/random';
import SelectWithSearch from '@/components/SelectWithSearch';

export default {
  name: 'AccountEditPage',
  components: { SelectWithSearch },
  computed: {
    ...mapGetters(['account/current']),
    editableAccountPermissions() {
      return [...this.editableAccount.permissions];
    },
    currentAccount() {
      return this['account/current'];
    },
  },
  data: () => ({
    password: '',
    companyId: 'default',

    companyList: [],

    availablePermissions: [],
    selectedPermission: null,

    editableAccount: null,
  }),
  methods: {
    async updateCompanies() {
      this.companyId = this.editableAccount?.companyId || 'default';

      const response = await getCompanyList(100, 0);
      if (response.errors) {
        this.$toast.error(response.errors[0].message);
        return;
      }

      this.companyList = response.data.companies;
    },

    async update(withReset = true) {
      try {
        if (withReset) {
          this.equipment = null;
        }

        const id = this.$route.params.id;
        if (!id) {
          return;
        }

        const response = await findAccount(id);
        if (response?.errors) {
          throw new Error(response.errors[0].message);
        }

        this.editableAccount = response?.data?.account;
        if (!this.editableAccount) {
          throw new Error('Account not found');
        }

        this.companyId = this.editableAccount?.companyId || 'default';
      } catch (error) {
        console.error(error);
        this.$toast.error(error.message);
      }
    },

    updatePermissions() {
      this.permissions = [...(this.editableAccount?.permissions || [])];
    },
    generatePassword() {
      this.password = randomString(16, RandomStringFlags.disableExtraCharacters);
    },
    async changePassword() {
      try {
        await accountService.changePassword(this.$route.params.id, this.password);
        this.$toast.success('Password changed.');
      } catch (err) {
        this.$toast.error(err.toString());
      }
    },
    async changeCompany() {
      try {
        await accountService.changeCompany(this.$route.params.id, this.companyId);
        this.$toast.success('Company changed.');
      } catch (err) {
        this.$toast.error(err.toString());
      }
    },
    async onPermissionSearch(search) {
      const response = await getPermissionList(search);

      if (response?.errors) {
        this.$toast.error(response.errors[0].message);
        return;
      }

      this.availablePermissions = response.data.permissions
        .filter(i => !this.permissions.includes(i.key))
        .map(i => ({
          title: i.key,
          subtitle: i.title,
          onClick: () => this.selectPermission(i),
        }));
    },

    selectPermission(permission) {
      this.selectedPermission = permission.key;
    },

    async grantPermission() {
      try {
        if (!this.selectedPermission) {
          return;
        }

        if (this.selectedPermission.startsWith('admin') && !this.currentAccount.hasPermission('admin.account.edit'))
          throw 'Only administrator can grant admin permissions.';

        await accountService.grantPermission(this.$route.params.id, this.selectedPermission);

        this.editableAccount.permissions.push(this.selectedPermission);
        this.selectedPermission = null;

        this.$toast.success('Permission granted.', { duration: 2000 });
      } catch (err) {
        this.$toast.error(err.toString());
      }
    },
    async revokePermission(permission) {
      try {
        if (permission.startsWith('admin') && !this.currentAccount.hasPermission('admin.account.edit'))
          throw 'Only administrator can revoke admin permissions.';

        await accountService.revokePermission(this.$route.params.id, permission);

        const permissionIndex = this.editableAccount.permissions.indexOf(permission);
        if (permissionIndex !== -1) {
          this.editableAccount.permissions.splice(permissionIndex, 1);
        }

        this.$toast.success('Permission revoked.');
      } catch (err) {
        this.$toast.error(err.toString());
      }
    },
  },
  activated() {
    this.update();
    this.updateCompanies();
    this.updatePermissions();
  },
  watch: {
    '$route.params.id': {
      handler() {
        this.update();
        this.updateCompanies();
        this.updatePermissions();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
.account-edit-page {
  padding: 2em 0;
}

.control input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.control button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.password {
  position: relative;
  width: 100%;
}
.password i {
  color: #212529;
  position: absolute;
  right: 1em;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.permission {
  margin: 0.2em;
}

.permission i {
  margin-left: 0.2em;
  cursor: pointer;
}

.add-permission label {
  display: block;
}

.add-permission input {
  width: auto;
  /*margin: 0 0.2em 0 0;*/
  display: inline-block;
}

.add-permission button {
  vertical-align: top;
}
</style>
